import React from "react";

import * as styles from "./section.module.scss";

const Section = ({ title, children, className, ...props }) => (
  <section className={styles.section} {...props}>
    {title && <h2 className={styles.title}>{title}</h2>}
    <div className={className}>{children}</div>
  </section>
);

export default Section;
